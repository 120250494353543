import styles from './LandingHeader.module.css';
import Image from 'next/image';
import logo from '../../../public/assets/images/interior-logo.svg';
import { EstimatePopupButton } from '@/components/estimated/EstimatePopupButton';
import { ElemenetType } from '@/lib/constants';
export function LandingHeader() {
    return (
        <header className={styles.header} >
            <div className="container1350">
                <div className={styles.headContainer}>
                    <div className={styles.weblogoBox}>
                        <Image
                            width={84}
                            height={37}
                            src={logo}
                            alt="Interior Company"
                            loading='eager'
                            className="img-responsive"
                        />
                    </div>
                    <div className={styles.nav}>
                        <div className={styles.freeButon}>
                            <EstimatePopupButton ElemenetType={ElemenetType.button} ButtonClass={`${styles.bookConsultation} secondaryBtn`} />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}