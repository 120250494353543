import { ModuleType } from '../../lib/constants';
import { Lead } from '../shared/lead/Lead';
import styles from './getestimate.module.css'

type Prop = {
    setEstimatePage: (arg: number) => void;
    IsGetEstimatePage: boolean;
    MessageForGetEstimate: string;
    countryCode: string
}

export function GetEstimated3({ setEstimatePage, IsGetEstimatePage, MessageForGetEstimate, countryCode }: Prop) {

    return (
        <div className={`${styles.estimateLeadF} estimateLeadF`}>
            <Lead ModuleType={ModuleType.INTERIOR} Title=' ' ButtonText='SUBMIT' IsGetEstimatePage={IsGetEstimatePage} MessageForGetEstimate={MessageForGetEstimate} setEstimatePage={setEstimatePage} countryCode={countryCode} />
        </div>
    );
}

