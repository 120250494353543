import styles from "./FreeEstimate.module.css";
import Image from "next/image";
export function FreeEstimate() {
    return (
        <section className="section">
            <div className="container1350">
                <div className={styles.getFreeesthead}>
                    <h3 className="font26 lineheightNom text222 mb20 fontSM20 mbSM15">No Guesses. No Surprises.</h3>
                    <p className="font20 lineheightSM3 text666 fontSM14">Get Free Estimates In 3 Easy Steps</p>
                </div>
                <div className={styles.getMoreestimain}>
                    <div className={styles.germoEstiles}>
                        <Image width={60} height={60} className="img-responsive" src="/assets/images/getestmateSone.svg" alt="" />
                        <div className={styles.getMoeintext}>
                            <span>Step 01:</span>
                            <p>Share Your Requirements</p>
                        </div>
                    </div>
                    <div className={styles.germoEstiles}>
                        <Image width={60} height={60} className="img-responsive" src="/assets/images/getFreesttwo.svg" alt="" />
                        <div className={styles.getMoeintext}>
                            <span>Step 02:</span>
                            <p>Get A Customised Quote</p>
                        </div>
                    </div>
                    <div className={styles.germoEstiles}>
                        <Image width={60} height={60} className="img-responsive" src="/assets/images/getfreestthree.svg" alt="" />
                        <div className={styles.getMoeintext}>
                            <span>Step 03:</span>
                            <p>Start Your Journey</p>
                        </div>
                    </div>
                </div>
                <div className={styles.getMoestmbtbox}>
                    <span className="font26 text666 fontSM14">No Hidden Cost | Quick and Easy | 100% Free</span>
                    <button className="primaryBtn" id="openExitIntentForm">Get A Free Estimate</button>
                </div>
            </div>
        </section>
    )
}