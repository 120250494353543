import { ITestimonial } from "@/interfaces";
import styles from "./CustomerReviews.module.css";
import Image from "next/image";
import { cmsLoader } from "@/lib/helpers/imageLoader";
import { use, useState } from "react";
import { useGlobalContext } from "@/lib/context";
type props = {
    testimoninals: ITestimonial[]
}
export function CustomerReviews({ testimoninals }: props) {
    const [vidioUrl, setVidioUrl] = useState("");
    const { props: { isMobileView } } = useGlobalContext();
    const [isPopUp, togglePopUp] = useState(false);
    function getYouTubeEmbedUrl(url: string): string {
        let videoId = '';
        const watchMatch = url.match(/[?&]v=([^&]+)/);
        if (watchMatch) videoId = watchMatch[1];
        const shortMatch = url.match(/youtu\.be\/([^?]+)/);
        if (shortMatch) videoId = shortMatch[1];
        return videoId ? `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&modestbranding=1&rel=0&showinfo=0&disablekb=1&fs=0&playsinline=1` : '';
    };
    const popUpHandler = (url: string) => {
        setVidioUrl(url);
        togglePopUp(!isPopUp);
    };
    const popUp = () => {
        return (
            <div className="overlay">
                <div className={styles.vidioBox}>
                    <div className={styles.closeModal} onClick={() => popUpHandler("")}>
                    </div>
                    <div className={styles.youTubepopup}>
                        <iframe width="100%" allowFullScreen height={isMobileView ? "300" : "600"} frameBorder="0" referrerPolicy="strict-origin-when-cross-origin" allow="autoplay" src={getYouTubeEmbedUrl(vidioUrl)} title="YouTube video player"></iframe>
                    </div>
                </div>
            </div>
        )
    };
    return (
        <>
            {
                isPopUp ? popUp() : null
            }
            <section className={`section bgLigtgray ${styles.youzTubainbox}`}>
                <div className={styles.backGrounimg}>
                    <Image width={57} height={40} className="img-responsive" src="/assets/images/youtubebgimg.svg" alt="" />
                </div>
                <div className="container1350">
                    <div className={styles.youTunesehead}>
                        <h3 className="font26 text222 mb20 fontSM20 mbSM15">Real Stories. Unreal Impact.</h3>
                        <span className="font20 text666 fontSM14 lineheightSM3">First-hand experiences of Interior Company’s clients.</span>
                    </div>
                    <div className={styles.youTubemainbox}>
                        {
                            testimoninals && testimoninals.slice(0, 6).map((item, index) => {
                                return (
                                    <div className={styles.youinTiles} key={index} onClick={() => popUpHandler(item.video_url)}>
                                        <div className={styles.youTileimgbox}>
                                            <Image width={424} height={420} loader={cmsLoader} className="img-responsive" src={item.post_relative} alt="" />
                                            <div className={styles.youTunimgbox}>
                                                <img className="img-responsive" src="/assets/images/youtubelogo.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className={styles.youTubelowertxbox}>
                                            <p>{item.post_content.slice(0, 150)}...</p>
                                            <div className={styles.youtuInertxb}>
                                                <span>{item.clinet_name}</span>
                                                <span>{item.inco_city}</span>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}