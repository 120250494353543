import styles from "./MediaCoverage.module.css";
import media from '../../../data/mediaCoverage.json'
export function MediaCoverage() {
    return (
        <section className="section bgLigtgray">
            <div className="container1350">
                <div className={styles.meadiaheadbox}>
                    <h3 className="font26 text222 lineheightNom fontSM20 textSMcenter">Media Coverage</h3>
                </div>
                <div className={styles.medaimainbox}>
                    {
                        media.map((d, idx) => (
                            <div className={styles.medaiTiles} key={idx}>
                                <img className="img-responsive" src={`/assets/images/${d.image}.png`} alt="" />
                                <p>“{d.subTxt}”</p>
                            </div>
                        ))
                    }
                </div>

            </div>

        </section>
    )
}