import { CountryCode, ElemenetType } from "@/lib/constants";
import { EstimatedMainPage } from "./EstimatedMainPage";
import { useState } from "react";
interface IFreeEstimateButton {
    children?: React.ReactNode;
    childrenText?: any;
    ElemenetType: ElemenetType;
    ButtonClass: string;
}
export function EstimatePopupButton({ children, childrenText, ElemenetType, ButtonClass }: IFreeEstimateButton) {
    const [isPopUp, togglePopUp] = useState(false);
    const popUpHandler = () => {
        togglePopUp(!isPopUp);
    }
    const Component = ElemenetType;
    const popUp = () => {
        return (
            <div className="overlay freeEstimate">
                <div className="regFormBox">
                    <div className='closeModal' onClick={popUpHandler}>
                        <div className='leadFormClose'></div>
                    </div>
                    <EstimatedMainPage countryCode={CountryCode.INR} isPopup={true} />
                </div>
            </div>
        )
    }
    return (
        <>
            {
                isPopUp ? popUp() : null
            }
            <Component className={ButtonClass} onClick={popUpHandler}>
                {
                    children ? children : (childrenText || "Get Free Estimate")
                }
            </Component>
        </>
    )
}