import styles from "./WhyChooseUs.module.css";
import Image from "next/image";
import { useRouter } from "next/router";

export function WhyChooseUs() {
    const router = useRouter();
    const { city } = router.query;

    return (
        <section className="section">
            <div className="container1350">
                <h3 className="font26 text222 textCenter mb20 fontSM20 mbSM15">Besides Great Variety, We Also Offer</h3>
                {/* <span className="font20 text666 dispBlok textCenter fontSM14">With us, you experience the power of ideas, design and craftsmanship come alive.</span> */}
                <div className={styles.whychoInermain}>
                    <div className={styles.whyChoeinleft}>
                        <div className={`${styles.whyChoeslefttiles} ${styles.bdRightno} ${styles.bdBottomno}`}>
                            <Image width={60} height={60} className="img-responsive mb16" src="/assets/images/maketoorder.svg" alt="" />
                            <span className="font16 text000 dispBlok fontSM12">Made-to-Order Interiors</span>
                            <p className="font16 text000 dispBlok fontSM12">for customisation</p>
                        </div>
                        <div className={`${styles.whyChoeslefttiles} ${styles.bdBottomno}`}>
                            <Image width={60} height={60} className="img-responsive mb16" src="/assets/images/money-loss.svg" alt="" />
                            <span className="font16 text000 dispBlok fontSM12">Lowest prices</span>
                            <p className="font16 text000 dispBlok fontSM12">for affordability</p>
                        </div>
                        <div className={`${styles.whyChoeslefttiles} ${styles.bdRightno} ${styles.bdBottomno}`}>
                            <Image width={60} height={60} className="img-responsive mb16" src="/assets/images/step-1.svg" alt="" />
                            <span className="font16 text000 dispBlok fontSM12">Quality Check</span>
                            <p className="font16 text000 dispBlok fontSM12">for strength</p>
                        </div>
                        <div className={`${styles.whyChoeslefttiles} ${styles.bdBottomno}`}>
                            <Image width={60} height={60} className="img-responsive mb16" src="/assets/images/delivery-time-1.svg" alt="" />
                            <span className="font16 text000 dispBlok fontSM12">Timely delivery</span>
                            <p className="font16 text000 dispBlok fontSM12">for commitment</p>
                        </div>
                    </div>
                    <div className={styles.whyChoesright}>
                        <Image width={60} height={60} className="img-responsive mb16" src="/assets/images/shield-1-1.svg" alt="" />
                        <span className="font16 text000 dispBlok fontSM12">{city === 'dubai' ? 5 : 11}-Year Warranty</span>
                        <p className="font16 text000 dispBlok fontSM12">for trust</p>
                    </div>
                </div>
                <div className={styles.bokkConsbtbox}>
                    <button className="btnPrimery" id="openExitIntentForm">
                        Book A Free Consultation
                    </button>
                </div>
            </div>
        </section>
    )
}