import { useEffect, useState, useRef } from "react";
import styles from "./DesignExperts.module.css";
import Image from "next/image";

export function DesignExperts() {
    const [counts, setCounts] = useState({
        projects: 0,
        experts: 0,
        cities: 0,
        options: 0
    });

    const sectionRef = useRef<HTMLElement | null>(null);
    const hasAnimated = useRef(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && !hasAnimated.current) {
                    hasAnimated.current = true;
                    animateCount(5276, "projects");
                    animateCount(300, "experts");
                    animateCount(10, "cities");
                    animateCount(200000, "options");
                }
            },
            { threshold: 0.5 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const animateCount = (endValue: number, key: keyof typeof counts) => {
        let start = 0;
        const duration = 2000;
        const stepTime = Math.max(duration / endValue, 10);
        const interval = setInterval(() => {
            start += Math.ceil(endValue / (duration / stepTime));
            if (start >= endValue) {
                start = endValue;
                clearInterval(interval);
            }
            setCounts((prev) => ({ ...prev, [key]: start }));
        }, stepTime);
    };

    return (
        <section className={styles.interProjects} ref={sectionRef}>
            <div className={styles.intermainledImgb}>
                <Image width={140} height={140} className="img-responsive" src="/assets/images/interiorproleft.svg" alt="interiorproleft" />
            </div>
            <div className="container1350">
                <div className={styles.interProjectinmain}>
                    <div className={`${styles.interProjtiles} ${styles.bdRightno} ${styles.bdBottomno}`}>
                        <Image width={45} height={45} className="img-responsive mb16 mbSM10" src="/assets/images/interiorproject.svg" alt="interiorproject" />
                        <span className="font30 textfff f600 dispBlok lineheightNom mb16 fontSM20 mbSM10">
                            {counts.projects}+
                        </span>
                        <span className="font16 textfff dispBlok lineheightNom fontSM12">Interior Projects</span>
                    </div>
                    <div className={`${styles.interProjtiles} ${styles.bdBottomno}`}>
                        <Image width={45} height={45} className="img-responsive mb16 mbSM10" src="/assets/images/2000plusdeshin.svg" alt="2000plusdeshin" />
                        <span className="font30 textfff f600 dispBlok lineheightNom mb16 fontSM20 mbSM10">
                            {counts.experts}+
                        </span>
                        <span className="font16 textfff dispBlok lineheightNom fontSM12">Design Experts</span>
                    </div>
                    <div className={`${styles.interProjtiles} ${styles.bdRightno}`}>
                        <Image width={45} height={45} className="img-responsive mb16 mbSM10" src="/assets/images/10citys.svg" alt="10citys" />
                        <span className="font30 textfff f600 dispBlok lineheightNom mb16 fontSM20 mbSM10">
                            {counts.cities} Cities
                        </span>
                        <span className="font16 textfff dispBlok lineheightNom fontSM12">2 Countries</span>
                    </div>
                    <div className={styles.interProjtiles}>
                        <Image width={45} height={45} className="img-responsive mb16 mbSM10" src="/assets/images/2lacksdsgin.svg" alt="2lacksdsgin" />
                        <span className="font30 textfff f600 dispBlok lineheightNom mb16 fontSM20 mbSM10">
                            {counts.options.toLocaleString()}+
                        </span>
                        <span className="font16 textfff dispBlok lineheightNom fontSM12">Design Options</span>
                    </div>
                </div>
            </div>
        </section>
    );
}
