import { getYear } from '@/lib/helpers/common';
import styles from './LandingFooter.module.css';
import Image from 'next/image';
import { useRouter } from 'next/router';
export function LandingFooter() {
    const router = useRouter();
    const { city } = router.query;
    return (
        <footer className={styles.footer}>
            <div className="container1350">
                <div className={styles.interFootrlogobox}>
                    <img className="img-responsive" src="/assets/images/interior-logo-white.svg" alt="interior" />
                </div>
                <div className={styles.assurance}>
                    <ul>
                        <li><figure><Image width={112} height={80} src="/assets/images/lowest-prices-guranted.png" alt="Lowest Price" className="img-responsive" /></figure><div><label>Lowest Price Guaranteed</label><span>No false claims. No hidden costs. Just exquisite designs at extraordinary prices.</span></div></li>
                        <li><figure><Image width={85} height={80} src="/assets/images/delivery-assurance.png" alt="Delivery Assurance" className="img-responsive" /></figure><div><label>Timely Delivery Assurance</label><span>We honour our commitments. Delivering your interiors on time.</span></div></li>
                        {city != 'dubai' && <li><figure><Image width={79} height={79} src="/assets/images/no-cost-emi-prcnt.png" alt="No Cost Emi" className="img-responsive" /></figure><div><label>No Cost EMI Up to 10 Lacs</label><span>Contact our experts and check your eligibility.</span></div></li>}
                    </ul>
                </div>
                <div className={styles.copyrightSectn}>
                    <div className={styles.cpyrightDtl}>&#169; {getYear()} www.interiorcompany.com. All rights reserved</div>
                </div>
            </div>
        </footer>

    )
}