import React, { useState } from 'react';
import Image from 'next/image';
import styles from './TransformSpace.module.css';

export function TransformSpace() {
    const [sliderPosition, setSliderPosition] = useState(50);

    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSliderPosition(Number(e.target.value));
    };

    const preventDragHandler = (e: React.DragEvent<HTMLImageElement>) => {
        e.preventDefault();
    };
    const handleTouchMove = (e: React.TouchEvent<HTMLInputElement>) => {
        const touch = e.touches[0];
        const slider = e.currentTarget;
        const rect = slider.getBoundingClientRect();
        const newValue = ((touch.clientX - rect.left) / rect.width) * 100;
        setSliderPosition(Math.min(100, Math.max(0, newValue)));
    };
    return (
        <section className="section">
            <div className="container1350">
                <div className={styles.beForafsehead}>
                    <h3 className="font26 lineheightNom text222 mb20 fontSM20 textSMcenter lineheightSM3 mbSM15">
                        Your Home Transformation Begins Today
                    </h3>
                    <span className="font20 text666 lineheightSM3 textSMcenter fontSM14 dispBlok">
                        Get The Home You’ve Always Wanted
                    </span>
                </div>
                <div className={styles.imageComparisonContainer}>
                    <div
                        className={styles.imageComparisonWrapper}
                        style={{
                            '--slider-position': `${sliderPosition}%`
                        } as React.CSSProperties}
                    >
                        <Image
                            width={1200}
                            height={545}
                            src={process.env.CDN_URL+'/web/static/before.webp'}
                            alt="Before transformation"
                            className={styles.beforeImage}
                            onDragStart={preventDragHandler}
                            draggable={false}
                        />
                        <Image
                            width={1200}
                            height={545}
                            src={process.env.CDN_URL+'/web/static/after.jpg'}
                            alt="After transformation"
                            className={styles.afterImage}
                            onDragStart={preventDragHandler}
                            draggable={false}
                        />
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={sliderPosition}
                            onChange={handleSliderChange}
                            onTouchMove={handleTouchMove}
                            className={styles.slider}
                        />
                        <div className={styles.sliderHandle} style={{ left: `${sliderPosition}%` }}>
                            <div className={styles.sliderImgbox}>
                                <Image
                                    width={62}
                                    height={62}
                                    src="/assets/images/slider.svg"
                                    alt="slider"
                                    onDragStart={preventDragHandler}
                                    draggable={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.tranFeryobtbox}>
                    <button className="btnPrimery" id='openExitIntentForm'>
                        Connect Now
                    </button>
                </div>
            </div>
        </section>
    );
}