import { useRef, useState } from 'react';
import styles from './FactoryJourney.module.css';
export function FactoryJourney() {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };
    return (
        <section className="section">
            <div className="container1350">
                <div className={styles.funturemainBox}>
                    <div className={`${styles.futMinboxhead} mb46`}>
                        <h3 className="font26 text222">Our Factory</h3>
                    </div>
                    <div className={styles.funtLowmianbox}>
                        <div className={styles.funLftloerbox}>

                            <div className={styles.funtMiantextbox}>
                                <span>We Build From Scratch</span>
                                <p>The secret to our magic? Personal touch. Our experts craft your interiors with skill and perfection, overseeing each step with care.</p>
                            </div>
                            <div className={styles.furnuterJouweb}>
                                <img className="img-responsive" src="/assets/images/furenewbgimg.svg" alt="" />
                            </div>
                            <div className={styles.fureJoimgmob}>
                                <img className="img-responsive" src="/assets/images/furtuerdeshinimgmob.svg" alt="" />
                            </div>
                        </div>
                        <div className={styles.funRgtlowemainbox}>
                            <video
                                ref={videoRef}
                                width="100%"
                                controls={isPlaying ? true : false}
                                autoPlay={isPlaying}
                                loop
                                poster={`${process.env.CDN_URL}/web/static/factoryJourneythumbnail.jpg`}
                            >
                                <source src={`${process.env.CDN_URL}/web/static/factoryJourney.mp4`} type="video/mp4" />
                            </video>
                            {!isPlaying && (
                                <button
                                    className={styles.playButton}
                                    onClick={handlePlay}
                                    aria-label="Play Video"
                                >
                                    <img
                                        src="/assets/images/youtubelogo.svg"
                                        alt="Play Video"
                                        className={styles.youtubeIcon}
                                    />
                                </button>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}