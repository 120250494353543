import styles from "./CompleteTurkney.module.css";
import {  ModuleType } from "@/lib/constants";
import { Lead } from "@/components/shared/lead/Lead";
import { useGlobalContext } from "@/lib/context";
import Image from "next/image";
export function CompleteTurkney() {
    const { props: { isMobileView } } = useGlobalContext();
    return (
        <section className={styles.completServicsminbox}>
            <div className={styles.completTueyleftim}>
                <Image width={348} height={155} className="img-responsive" src="/assets/images/completbgimg.svg" alt="" />
            </div>
            <div className="container1350">
                <div className={styles.completeyTurkmainb}>
                    <div className={styles.comPletinleftbox}>
                        <span>The All-Rounders Of Interior Services</span>
                        {/* <p>With an emphasis on details,
                            extraordinary designs and
                            exceptional customer service,
                            we bring your dreams to life.</p> */}
                    </div>
                    <div className={styles.comPletturyrightm}>
                        <div className={styles.completTuertiles}>
                            <Image width={184} height={160} className="img-responsive" src="/assets/images/complerwedgin.svg" alt="" />
                            <span>We Design</span>
                            <p>From interiors to furniture, each product is personalised to meet your style.</p>
                        </div>
                        <div className={styles.completTuertiles}>
                            <Image width={166} height={160} className="img-responsive" src="/assets/images/completeweexcute.svg" alt="" />
                            <span>We Execute</span>
                            <p>Our masterful designers bring your vision to life with passion and precision.</p>
                        </div>
                        <div className={styles.completTuertiles}>
                            <Image width={117} height={160} className="img-responsive" src="/assets/images/completewemange.svg" alt="" />
                            <span>We Manage</span>
                            <p>Reliable customer care services - like our interiors, our quality lasts.</p>
                        </div>

                    </div>

                </div>
                <div className={styles.getFreeedtbt}>
                    <button className="btnPrimery" id="openExitIntentForm">Get Free Quote</button>
                </div>
            </div>
            {
                isMobileView &&
                <div className={styles.completingLeadbox}>
                    <div className="leadForm bgWhite nextLeadForm ">
                        <Lead ModuleType={ModuleType.INTERIOR} Title="Every Home Has a Unique Inside Story,Meet the Designer" ButtonText="Book Free Consultation" />
                    </div>
                </div>
            }
        </section>
    )
}