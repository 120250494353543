import { Lead } from "@/components/shared/lead/Lead";
import { ModuleType } from "@/lib/constants";
import styles from "./LandingBanner.module.css";
import { useRouter } from "next/router";
import Image from "next/image";
export function LandingBanner() {
    const router = useRouter();
    const { query } = router;
    return (
        <section>
            <div className={styles.campginpabannerm}>
                <div className={styles.cammainBannering}>
                    <Image width={1440} height={680} className="img-responsive" src={`${process.env.CDN_URL}/web/static/campaign-banner-img.png`} alt="campaign" />
                </div>
                <div className={styles.campingbannermob}>
                    <Image width={360} height={400} className="img-responsive" src={`${process.env.CDN_URL}/web/static/campinbannermob.png`} alt="campinbannermob" />
                </div>
                <div className="container1350">
                    <div className={styles.campainLedtextbox}>
                        <div className={styles.campTxtbox}>
                            <span className={styles.capmBoxtext}>Breathing Life Into Every Space. Every Corner.</span>
                            <span className={styles.comBoxlowtex}>From foundations to furnishings, we style your home like our own.</span>
                        </div>
                        <div className={styles.bookMyconbtbox}>
                            <button className="btnPrimery">Book Free Consultation</button>
                        </div>
                        <div className={styles.campingLeadbox}>
                            <div className={`${styles.exitnewFormBox} popup`}>
                                <Lead ModuleType={ModuleType.INTERIOR} Title="Every Home Has a Unique Inside Story, Meet the Designer" ButtonText="Book Free Consultation" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}