import React, { useState, ChangeEvent, useEffect } from 'react';
import Image from "next/image";
import { ILead } from '../../../interfaces';
import { Input as InputText, Select as SelectBox, NavLink } from '../../ui';
import { useGlobalContext, useLeadContext } from '../../../lib/context';
import { CountryCode, validationPattern } from '../../../lib/constants';
import whatsapp from '../../../public/assets/images/whatsapp.svg';
import styles from './LeadForm.module.css';
import { useRouter } from 'next/router';
import { API } from '@/lib/helpers/http-client';

const countryData = {
    in: {
        iconPath: "/assets/images/india.png",
        pattern: validationPattern.Number,
        countryCode: "91"
    },
    ae: {
        iconPath: "/assets/images/uae.svg",
        pattern: validationPattern.Number,
        countryCode: "971"
    }
}
type props = {
    Title: string;
    ButtonText: string;
    IsGetEstimatePage?: boolean;
    setEstimatePage?: (SetEstimatePage: number) => void;
}
export function LeadForm({ Title, ButtonText, IsGetEstimatePage, setEstimatePage }: props) {
    const { props: { cityMaster, countryCode, countryMaster, isDubaiPage, pageURL } } = useGlobalContext();
    const router = useRouter()
    const CityName = router.query.city?.toLocaleString().slugToText()
    const siteURL = `${process.env.SITE_URL}/${isDubaiPage ? 'dubai' : countryCode}`;
    const [cities, setCities] = useState(cityMaster);
    const { lead, sendOtp, apiError } = useLeadContext();
    const _country = countryData[isDubaiPage ? CountryCode.AED : countryCode];
    const [getValue, setValue] = useState<ILead>({ ...lead!});
    const [errors, setErrors] = useState({
        Name: "",
        ProjectCity: "",
        MobileNo: "",
        Email: "",
        WhoAreYou: ""
    });
    const handlePhoneNo = async ()=>{
        let isValiate = true;
            try {
                const result = await API.post('/api/validatephone',{
                    countryCode:getValue.CountryCode,
                    mobileNo:getValue.MobileNo
                })
                if(result && result.data == 'false'){
                setErrors({ ...errors, ["MobileNo"]:'Please enter a valid mobile number' });  
                isValiate = false
                } else isValiate = true
            } catch (error) {
                console.error(error)         
            } 
            return isValiate
        }
   
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        const isValid = isAllowed(name, value)
        if (isValid) {
            if (type == "checkbox")
                setValue({ ...getValue, [name]: (checked ? 1 : 0) });
            else
            {
                setErrors({ ...errors, [name]: "" });
                setValue({ ...getValue, [name]: value });
            }
        }
    };

    const handlePaste = (e:React.ClipboardEvent<HTMLInputElement>)=> {
        e.preventDefault();
        const name = (e.target as HTMLInputElement).name;
        const pastedValue = e.clipboardData.getData('text/plain');
        const isValid = isAllowed(name, pastedValue)
        if (isValid) {
        setValue({ ...getValue, [name]: pastedValue });
        setErrors({ ...errors, [name]: "" });
        }
    }
    const fetchCities = async (countryCode: CountryCode) => {
        try {
            const getCities = await API.get(`/api/master?attributes=cities&countryCode=${countryCode}`);
            if (getCities.status == 200 && getCities.data && Object.keys(getCities.data).length > 0) {
                setCities(getCities.data.cities)
            }
        } catch (error) {
            console.error(error)
        }
    }
    const isAllowed = (name: string, value: string) => {
        const Character = new RegExp(validationPattern.Character);
        const Number = new RegExp(validationPattern.Number);
        switch (name) {
            case 'Name':
                return (value === '' || (Character.test(value)))
            case 'MobileNo':
                return (value === '' || (Number.test(value)))
            default:
                return true;
        }

    }
    const checkValidation = () => {
        const _errors = errors;
        let isValidate = true;
        if (!getValue.Name || getValue.Name.trim() == "") {
            _errors.Name = "Please enter your name";
            isValidate = false;
        }
        else
            _errors.Name = "";
        if (!getValue.ProjectCity || getValue.ProjectCity.trim() == "") {
            _errors.ProjectCity = "Please select your city";
            isValidate = false;
        }
        else
            _errors.ProjectCity = "";
        const emailRegex = new RegExp(validationPattern.Email);
        if (!getValue.Email || getValue.Email.trim() == "") {
            _errors.Email = "Please enter your email address";
            isValidate = false;
        } else if (!emailRegex.test(getValue.Email.trim())) {
            _errors.Email = "Please Enter a Valid Email";
            isValidate = false;
        }
        else
            _errors.Email = "";
        if (!getValue.MobileNo || getValue.MobileNo.trim() == "") {
            _errors.MobileNo = "Please enter your phone number";
            isValidate = false;
        }
        else
            _errors.MobileNo = "";
        if (pageURL.includes('/landing-page')) {
            _errors.WhoAreYou = "";
        } else {
            if (!getValue.WhoAreYou) {
                _errors.WhoAreYou = "Please select your interest";
                isValidate = false
            }
            else _errors.WhoAreYou = "";
        }
        setErrors({ ..._errors });
        return isValidate;
    }

    const submitData = async (e: any) => {
        e && e.preventDefault();
        if (checkValidation() && await handlePhoneNo()){
         await sendOtp?.(getValue)
        }
         else return ;
    };
    useEffect(() => {
        if (CityName) {
            setValue({ ...getValue, ["ProjectCity"]: CityName === "Gurugram" ? "Gurgaon" : CityName, ["CountryCode"]: _country.countryCode });
        }
        if (isDubaiPage) {
            fetchCities(CountryCode.AED);
        }
    }, [CityName,countryCode]);
    return <>

        {!IsGetEstimatePage && <div className="font20 mb16 lineHeight26 text222 f600 fontSM22 lineheightSM3 modelTitle">{Title}</div>}
        <div className={errors["Name"] && "formItem error" || "formItem"}>  {/* add error class */}
            <InputText type="text" autoComplete='true' name="Name" value={getValue.Name} required={true} maxLength={50} onChange={handleChange} onPaste={handlePaste} className="formControl leadName" />
            <label htmlFor='Name' className="formLable">Name</label>
            <span className='errorText'>{errors["Name"]}</span>
        </div>
        <div className={errors["MobileNo"] && "formItem error" || "formItem"}>
            <div className="mobileInput">
                    <div className='dropDown'>
                        <SelectBox name="CountryCode" value={getValue.CountryCode} className="countryCode  formControl leadCountryCode" onChange={handleChange}>
                            <option value="" disabled>-</option>
                            {countryMaster && countryMaster.map((code,idx)=><option key={idx} value={code}>+{code}</option>)}
                        </SelectBox>
                    </div>
                <InputText type="tel" autoComplete='true' name="MobileNo" value={getValue.MobileNo} required={true} maxLength={15} onChange={handleChange} onBlur={handlePhoneNo} onPaste={handlePaste} className="formControl leadMobileNo"/>
                <label htmlFor='MobileNo' className="formLable">Mobile</label>
            </div>
            <span className='errorText'>{errors["MobileNo"]}</span>
        </div>
        <div className={errors["Email"] && "formItem error" || "formItem"}>
            <InputText type="text" name="Email" autoComplete='true' value={getValue.Email} required={true} onChange={handleChange} onPaste={handlePaste} className={`${styles.whiteBg} formControl leadEmail`} />
            <label htmlFor='Email' className="formLable">Email</label>
            <span className='errorText'>{errors["Email"]}</span>
        </div>
        {
            !pageURL.includes('/landing-page') &&
            <>
                <div className={errors["ProjectCity"] && "formItem error" || "formItem"}>
                    <div className="dropDown">
                        <SelectBox name="ProjectCity" value={getValue.ProjectCity} className="formControl leadProjectCity" onChange={handleChange} >
                            <option value="" disabled>Choose City</option>
                            {cities && cities.map((city, idx) => <option key={idx} value={city.Name}>{city.Name}</option>)}
                        </SelectBox>
                    </div>
                    <span className='errorText'>{errors["ProjectCity"]}</span>
                </div>
                <div className="formItem dropDown">
                    <SelectBox name="WhoAreYou" value={getValue.WhoAreYou} className="formControl" onChange={handleChange} >
                        <option value="" disabled >I&apos;m Interested in Interiors For</option>
                        <option value="New Home For Self-Use">New Home For Self-Use</option>
                        <option value="New Home For Renting Out">New Home For Renting Out</option>
                        <option value="Home Renovation">Home Renovation</option>
                    </SelectBox>
                    <span className='errorText'>{errors["WhoAreYou"]}</span>
                </div>
            </>
        }
        <div className="switchBtn">
            <input type="checkbox" name='WhatsApp' onChange={handleChange} checked={getValue.WhatsApp == 1} />
            <label htmlFor='WhatsApp'>You can reach me on <span><em><Image
                src={whatsapp}
                width={16}
                height={16}
                alt="whatsapp"
            /></em> WhatsApp</span> </label>
        </div>
        <div className="posR">
            {apiError && <div className="apiError">{apiError}</div>}
            {!IsGetEstimatePage && <button className="btn btnPrimery btnBlock" onClick={submitData} >{ButtonText}</button>}
        </div>
        {IsGetEstimatePage && <div className={styles.getEstibuttonlow}>
            <button className={`${styles.getbtback} font16`} onClick={() => setEstimatePage && setEstimatePage(2)}>BACK</button>
            <button className="primaryBtn font16" onClick={submitData}>SUBMIT</button>
        </div>}
        <div className="termsOfusez textCenter">
            <span>By submitting this form, you agree to the <NavLink className="link" href={siteURL + "/privacy-policy"}>privacy policy</NavLink>&nbsp;and<NavLink className="link" href={siteURL + "/terms-and-conditions"}>&nbsp;terms of use</NavLink></span>
        </div>
    </>;
}