import { useState } from 'react';
import { IGetEstimatedBoxIndex, IGetLeadEstimatePageMessage } from '../../interfaces';
import styles from './getestimate.module.css'

type Prop = {
    setEstimatePage: (arg: number) => void;
    estimateSetMessage: (arg2: IGetLeadEstimatePageMessage) => void;
    estimateMessage: IGetLeadEstimatePageMessage;
    setGetEstimatedBoxIndex: (arg: IGetEstimatedBoxIndex) => void;
    getEstimatedBoxIndex: IGetEstimatedBoxIndex;
}
export function GetEstimated2({ setEstimatePage, estimateSetMessage, estimateMessage, setGetEstimatedBoxIndex, getEstimatedBoxIndex }: Prop) {
    const allPTags = ["Modular Kitchen", "Wardrobe", "EntertainmentUnit", "Loose Furniture", "False Ceiling", "Painting"];

    const [ScopeType, SetScopeType] = useState(false);
    return (
        <div className={`${styles.estimateLeadF} estimateLeadF`}>
            <h2 className="text000 fbold font16 mb16">Scope of Work</h2>
            <ul>
                {allPTags.map((Name: string, index: number) => {

                    const IncrementValue = () => {
                        SetScopeType(false);
                        estimateSetMessage({ ...estimateMessage, ["ScopeOfWorkText"]: { ...estimateMessage.ScopeOfWorkText, [index]: (allPTags[index] + "-" + (getEstimatedBoxIndex.ScopeOfWork[index] + 1)) } })
                        setGetEstimatedBoxIndex({ ...getEstimatedBoxIndex, ["ScopeOfWork"]: { ...getEstimatedBoxIndex.ScopeOfWork, [index]: (getEstimatedBoxIndex.ScopeOfWork[index] + 1) } });
                    }

                    const DecrementValue = () => {
                        SetScopeType(false);
                        estimateSetMessage({ ...estimateMessage, ["ScopeOfWorkText"]: { ...estimateMessage.ScopeOfWorkText, [index]: getEstimatedBoxIndex.ScopeOfWork[index] > 1 ? (allPTags[index] + "-" + (getEstimatedBoxIndex.ScopeOfWork[index] - 1)) : "" } })
                        setGetEstimatedBoxIndex({ ...getEstimatedBoxIndex, ["ScopeOfWork"]: { ...getEstimatedBoxIndex.ScopeOfWork, [index]: getEstimatedBoxIndex.ScopeOfWork[index] > 0 ? (getEstimatedBoxIndex.ScopeOfWork[index] - 1) : getEstimatedBoxIndex.ScopeOfWork[index] } })
                    }

                    return (
                        <li key={index}>
                            <div className={`${styles.estimateList} mb16`}>
                                <p className="text222 f600 font14">{(Name == "EntertainmentUnit") ? "Entertainment Unit" : Name}</p>
                                <div className={styles.estimatePosnag}>
                                    <div className={styles.getmin} onClick={() => DecrementValue()}><p className="text222 font24" >-</p></div>
                                    <p className="text000 font14">{getEstimatedBoxIndex.ScopeOfWork[index]}</p>
                                    <div className={styles.getplus} onClick={() => IncrementValue()} ><p className="text222 font24" >+</p></div>
                                </div>
                            </div>
                        </li>
                    )
                })}
                {ScopeType && <span className={styles.scopeOfWorkErrorMsg}>Please select at least one scope</span>}
            </ul>
            <div className={styles.getEstibuttonlow}>
                <button className={`${styles.getbtback} font16`} onClick={() => setEstimatePage(1)}> BACK</button>
                <button className="primaryBtn font16" onClick={() => (Object.values(getEstimatedBoxIndex.ScopeOfWork).some(value => value > 0)) ? setEstimatePage(3) : SetScopeType(true)} >NEXT</button>
            </div>
        </div>
    );
}

