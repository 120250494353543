import styles from './InteriorSpace.module.css';
import Image from 'next/image';
export function InteriorSpace() {
    return (
        <section className={styles.beutiFullsecmain}>
            <div className={styles.beautifulseimgbox}>
                <Image width={1440} height={380} className="img-responsive" src="/assets/images/befutifulseweb.svg" alt="befutifulseweb" />
            </div>
            <div className={styles.beftifulmobimgbox}>
                <Image width={425} height={424} className="img-responsive" src="/assets/images/beautifulsecmob.svg" alt="beautifulsecmob" />
            </div>
            <div className="container1350">
                <div className={styles.beautifulstextbox}>
                    <strong className={styles.beautifulstrog}>Beautiful Interiors</strong>
                    <span className={styles.beautifulspan}>At The Best Price</span>
                    <button className={`btnPrimery ${styles.beautifulbtn}`} id='openExitIntentForm'>Schedule a Free Consultation Today! </button>
                </div>
            </div>
        </section>
    )
}