import { useState } from 'react';
import styles from './getestimate.module.css'

export function LongBodyGetEstimatedIN() {
    const [readMore, setReadMore] = useState(false);

    return (
        <section className={`${styles.readMore} bgGray`}>
            <div className="container">
               
                    <div
                        className={`${styles.lowerContnt} ${readMore ? styles.fullHeight : null
                            }`}
                    >
                        <div>
                            <h2>How to Calculate Interior Design Cost?</h2>
                            <span>Determining the cost of an interior design project should be a top priority for every homemaker. The budget estimate of interior design is tailor-made and varies from home to home. Numerous factors influence the project cost. Knowing how every element contributes to the overall cost will help you lay out the financial budget before your project takes off.</span>
                            
                            <h3>Size of the Area</h3>
                            <span>The available space is the most important factor in determining the interior design estimate. It is obvious- the bigger the area, the higher the cost. Before you finalise the material and design, getting an accurate space measurement is essential. Discuss the project’s scope with your designer so that you know the financial outlines.</span>
                            
                            <h3>The Vision</h3>
                            <span>The project’s complexity or vision is a crucial interior cost estimator. Before the execution takes place, you need to ask yourself a cost-deciding question. Do you want a simple, sober interior design or a luxurious, complex demeanour? To state the obvious, an opulent and detailed design would incur more costs because of the high-end elements.</span>
                            
                            <h3>Cost of Labour</h3>
                            <span>The cost estimation of interior work can only be made with including the labour cost. Any interior design project requires more than one person who will charge for their services. From a carpenter to a painter, you would have to foresee their cost in your budget.</span>
                            
                            <h3>Furnishings and Material</h3>
                            <span>The materials used and the furniture also constructed significantly impact the interior design calculations. Expensive materials like gold or marble will increase the overall amount on the total bill. Similarly, custom-made furniture will also cost extra in comparison to modular furniture. Remember to ask your designer about the prices before you finalise the budget.</span>
                            
                            <h3>Project Type</h3>
                            <span>The type of project is bound to alter the cost estimation in interior designing. For instance, the cost of designing a commercial project would be greater than planning out a residential project. Commercial projects are more complicated and require the assistance of professional designers which also increases the cost.</span>
                        </div>
                    </div>
                <span className={styles.link} onClick={() => setReadMore(!readMore)}>
                    Read {readMore ? "Less" : "More.."}
                </span>
            </div>
        </section>
    );
}

