import styles from "./GoogleReviewBox.module.css";
import { IReviews, IReviewsArr } from "@/interfaces/IReviews";
import { useGlobalContext } from "@/lib/context";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Image from "next/image";
type props = {
    reviewsdata: IReviews[]
}
export function GoogleReviewBox({ reviewsdata }: props) {
    if (!reviewsdata || reviewsdata.length == 0) return null;
    const { props: { isMobileView } } = useGlobalContext();
    const router = useRouter()
    const City = router.query.city
    const filterCity = City == "gurugram" ? "gurgaon" : City
    const data: IReviews[] = [];
    let totalratings = 0
    let totalReviews = 0
    reviewsdata && reviewsdata.map((e) => {
        data.push(e);
        totalratings += e.rating;
        totalReviews += e.user_ratings_total
    })
    const [filterdata, setfilteredData] = useState(data && data.find((f) => (f.city).toLowerCase() == filterCity) || data[0])
    const [active, setActive] = useState(filterCity || "all");
    useEffect(() => {
        setActive(filterCity || "all")
        setfilteredData(data && data.find((f) => (f.city).toLowerCase() == filterCity) || data[0])
    }, [router.query.city]);
    if (!reviewsdata || reviewsdata.length == 0) return null;
    return (
        <>
            <section className={`${styles.googleReviewSection} bgGray`}>
                <div className='container1350 posR'>
                    <h2 className={`font26 text000  f500 mb20 ${styles.reviweHeading} `}>
                        Hear From Our Customers
                    </h2>
                    <p className={`font16 text666 mb46 ${styles.reviwePara}`}>Testimonials of creativity, care & craftsmanship.</p>
                    <div className={styles.googleReviweBox}>
                        {reviewsdata.length > 1 && active == "all" ?
                            <div className={styles.leftRewviwBox}>
                                <Image
                                    className="img-responsive mb16"
                                    height={!isMobileView ? 38 : 110}
                                    width={!isMobileView ? 235 : 91}
                                    src={!isMobileView ? "/assets/images/Google-reviw-img.svg" : "/assets/images/Google-reviw-img.webp"}
                                    alt=""
                                />
                                <span className={!isMobileView ? "text222 font70 mb16" : "text222 font70"} >
                                    {(totalratings / data.length).toFixed(1)}
                                </span>
                                <div className={styles.starRating} >
                                    <div className={`${styles.starBox} mb16`}>
                                        <div className={styles.rating}>
                                            <div className={styles.ratingColor} style={{ width: `${(totalratings / data.length) * 20}%` }}></div>
                                        </div>
                                    </div>
                                    <p className="text666 font16 reviewBased">
                                        Based on {totalReviews} Reviews
                                    </p>
                                </div>
                            </div> : <div className={styles.leftRewviwBox}>
                                <Image
                                    className="img-responsive mb16"
                                    height={!isMobileView ? 38 : 110}
                                    width={!isMobileView ? 235 : 91}
                                    src={!isMobileView ? "/assets/images/Google-reviw-img.svg" : "/assets/images/Google-reviw-img.webp"}
                                    alt=""
                                />
                                <span className={!isMobileView ? "text222 font70 mb16" : "text222 font70"} >
                                    {filterdata.rating}
                                </span>
                                <div className={styles.starRating} >
                                    <div className={`${styles.starBox} mb16`}>
                                        <div className={styles.rating}>
                                            <div className={styles.ratingColor} style={{ width: `${(filterdata.rating) * 20}%` }}></div>
                                        </div>
                                    </div>
                                    <p className="text666 font16 reviewBased">
                                        Based on {filterdata.user_ratings_total} Reviews
                                    </p>
                                </div>
                            </div>}

                        <div className={styles.rightReviewBox}>
                            <div className={styles.rightrateviewBox}>
                                <div className={styles.typeOfModular}>
                                    {/* <div className={styles.typeOfModular}> */}
                                    <ul>
                                        {reviewsdata.length > 1 ? <> <li className={active == "all" ? styles.active : ""} onClick={() => setActive("all")}>All</li>
                                            {data.map((listcity, index) =>
                                                <li key={index} className={active == listcity.city.toLowerCase() ? styles.active : ""} onClick={() => { setActive(listcity.city.toLowerCase()); setfilteredData(listcity) }}  >{listcity.city}</li>)}</> :
                                            <li className={styles.active}>{reviewsdata[0].city}</li>}
                                    </ul>
                                </div>
                                {!isMobileView && <>
                                    {active == "all" ?
                                        <div className={styles.usergoogleRewBox}>
                                            {
                                                data.map((f) => f.reviews.filter((f) => f.rating >= 3).sort((a, b) => (b.rating - a.rating)).map((reviews, index) =>
                                                    <div key={index} className={styles.userFulldeatlis}>
                                                        <p className="font16 text000 fbold mb12 userReviwname">{reviews.author_name}</p>

                                                        <div className={`${styles.ratingpoinBox} mb12`}>
                                                            {/* <p className="font14 text000 fbold">{reviews.rating}</p> */}
                                                            <div className={styles.rating}>
                                                                <div className={styles.ratingColor} style={{ width: `${reviews.rating * 20}%` }}></div>
                                                            </div>
                                                            <p>{reviews.relative_time_description}</p>
                                                        </div>
                                                        <p className="text222 font14 lineHeight20 userCommnet">{reviews.text}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        : <div className={styles.usergoogleRewBox}>
                                            {filterdata.reviews.filter((f) => f.rating >= 3).sort((a, b) => (b.rating - a.rating)).map((reviews: IReviewsArr, index: number) =>
                                                <div key={index} className={styles.userFulldeatlis}>
                                                    <p className="font16 text000 fbold mb12 userReviwname">{reviews.author_name}</p>
                                                    <div className={`${styles.ratingpoinBox} mb12`}>
                                                        {/* <p className="font14 text000 fbold">{reviews.rating}</p> */}
                                                        <div className={styles.rating}>
                                                            <div className={styles.ratingColor} style={{ width: `${reviews.rating * 20}%` }}></div>
                                                        </div>
                                                        <p>{reviews.relative_time_description}</p>
                                                    </div>
                                                    <p className="text222 font14 lineHeight20 userCommnet">{reviews.text}</p>
                                                </div>)}
                                        </div>}
                                </>}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container1350">
                    <div className={styles.googleRewvbtbox}>
                        <button className="btnPrimery" id="openExitIntentForm">Start Your Journey Today</button>
                    </div>
                </div>
            </section>
        </>
    )
}