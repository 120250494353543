import styles from './getestimate.module.css'
import Image from "next/image";
import { useEffect, useState } from "react";
import GetEstimated1 from "./GetEstimated1";
import { GetEstimated2 } from "./GetEstimated2";
import { GetEstimated3 } from "./GetEstimated3";
import { NavLink } from '../ui';
import { CountryCode } from "../../lib/constants";
import { LongBodyGetEstimatedIN } from './LongBodyGetEstimatedIN';
import { LongBodyGetEstimatedAE } from './LongBodyGetEstimatedAE';

type Prop = {
    countryCode: string,
    isPopup?: boolean
}

export function EstimatedMainPage({ countryCode, isPopup }: Prop) {
    const [getEstimatePage, setEstimatePage] = useState(1);

    const [estimateMessage, estimateSetMessage] = useState({
        propertyType: "",
        purposeType: "",
        ScopeOfWorkText: new Array(6).fill("")
    });

    const [getEstimatedBoxIndex, setGetEstimatedBoxIndex] = useState({
        PropertyTypeIndex: 0,
        PurposeTypeIndex: 0,
        ScopeOfWork: new Array(6).fill(0)
    });

    const [MessageForGetEstimate, SetMessageForGetEstimate] = useState("");

    useEffect(() => {
        const ScopeOfWorkText = [estimateMessage.ScopeOfWorkText[0], estimateMessage.ScopeOfWorkText[1], estimateMessage.ScopeOfWorkText[2], estimateMessage.ScopeOfWorkText[3], estimateMessage.ScopeOfWorkText[4], estimateMessage.ScopeOfWorkText[5]].filter((e) => e != "")

        if (ScopeOfWorkText.length > 0) {
            SetMessageForGetEstimate([estimateMessage.propertyType, estimateMessage.purposeType].filter((e) => e != "").join(" | ") + " | Scope Of Work : " + ScopeOfWorkText.join(" | "))
        } else {
            SetMessageForGetEstimate([estimateMessage.propertyType, estimateMessage.purposeType].filter((e) => e != "").join(" | "));
        }

    }, [estimateMessage])

    const getForm = () => {
        switch (getEstimatePage) {
            case 1:
                return <GetEstimated1 setEstimatePage={setEstimatePage} estimateSetMessage={estimateSetMessage} setGetEstimatedBoxIndex={setGetEstimatedBoxIndex} getEstimatedBoxIndex={getEstimatedBoxIndex} estimateMessage={estimateMessage} />
            case 2:
                return <GetEstimated2 setEstimatePage={setEstimatePage} estimateSetMessage={estimateSetMessage} estimateMessage={estimateMessage} setGetEstimatedBoxIndex={setGetEstimatedBoxIndex} getEstimatedBoxIndex={getEstimatedBoxIndex} />
            default:
                return <GetEstimated3 setEstimatePage={setEstimatePage} IsGetEstimatePage={true} MessageForGetEstimate={MessageForGetEstimate} countryCode={countryCode} />
        }
    }

    return (
        <section>
            <div className={styles.getEstimateweb}>
                <div className="container">
                    <div className={`${styles.getEstimateBox} getEstimateBox`}>
                        <div className={styles.getEstimateImgtiles}>
                            <Image
                                alt='getEstimateimg2'
                                className="img-responsive"
                                src={"/assets/images/getEstimateimg2.svg"}
                                width={319}
                                height={394}
                            />
                        </div>

                        <div className={styles.getEstimateLeadform}>
                            {/* {countryCode == CountryCode.INR ? <div className={`${styles.closeBtn} closeBookConsultModal mb16`}><NavLink href='/'><span><svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                xmlns="http://www.w3.org/2000/svg"><path d="M13 1 1 13M1 1l12 12" stroke="#222" strokeLinecap="round" strokeLinejoin="round" /></svg></span>
                            </NavLink></div> : <div className={`${styles.closeBtn} closeBookConsultModal mb16`}><NavLink href='/ae'><span><svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                xmlns="http://www.w3.org/2000/svg"><path d="M13 1 1 13M1 1l12 12" stroke="#222" strokeLinecap="round" strokeLinejoin="round" /></svg></span>
                            </NavLink></div>} */}

                            <div className={`${styles.getEstimateleadhead} mb16`}>
                                {countryCode == "in" ? <h1 className="text000 fbold font20">Interior Design Cost Estimator</h1> : <h1 className="text000 fbold font20">Home Interior Cost Estimator</h1>}
                                <p className="text000 fbold font16">STEP {getEstimatePage} of 3</p>
                            </div>

                            {getForm()}

                        </div>

                        <div className={styles.getEstimateImgtiles}>
                            <Image
                                alt='getEstimateImg1'
                                className="img-responsive"
                                src={"/assets/images/getEstimateImg1.svg"}
                                width={317}
                                height={394}
                            />
                        </div>
                    </div>

                </div>
                {!isPopup && (countryCode == CountryCode.INR ? <LongBodyGetEstimatedIN /> : <LongBodyGetEstimatedAE />)}
            </div>
        </section>
    );
}
