import { useGlobalContext } from '@/lib/context';
import styles from './DesignSpace.module.css';
import { IProduct, IProductTiles } from '@/interfaces';
import { useState } from 'react';
import Image from 'next/image';
import imageLoader from '@/lib/helpers/imageLoader';
type props = {
    products: IProduct[];
}
export function DesignSpace({ products }: props) {
    const { props: { isMobileView } } = useGlobalContext();
    const [active, setActive] = useState(products[0] && products[0].categorySlug)
    const handleClick = (e: any, CategorySlug: string) => {
        setActive(CategorySlug)
    }
    const scrolltile = (direction: string, categoryNameId: string) => {
        const productBxId: any = document.getElementById(categoryNameId)
        if (productBxId != null) {
            if (direction === "prev") {
                productBxId.scrollLeft -= isMobileView ? 50 : 130;
            } else {
                productBxId.scrollLeft += isMobileView ? 50 : 130;
            }
        }
    }
    return (
        <section className="section bgLigtgray">
            <div className="container1350">
                <div className={styles.desDinodeHead}>
                    <h3 className="font26 text222 lineheightNom mb20 fontSM20 mbSM15">Design Ideas for Every Space</h3>
                    <span className="font20 text666 fontSM14">If It’s There, We Can Style It</span>
                </div>
                <div className={styles.typeOfModular}>
                    {products.length >= 7 &&
                        <span className={styles.leftArrow} onClick={() => scrolltile("prev", "designIdeaForEverySpace")}>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1 1 7l6 6" stroke="#222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                    }
                    <ul id='designIdeaForEverySpace'>
                        {products.map((productlist, index) =>
                            <li key={index} className={active == productlist.categorySlug ? styles.active : ""} onClick={(e) => handleClick(e, productlist.categorySlug)}>{productlist.categoryName}</li>)}
                    </ul>
                    {products.length >= 7 &&
                        <span className={styles.rightArrow} onClick={() => scrolltile("next", "designIdeaForEverySpace")} >
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="m1 13 6-6-6-6" stroke="#222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                    }
                </div>
                {
                    !isMobileView ?
                        <>
                            {
                                products && products.map((f, idx) =>
                                    <div className={`${active == f.categorySlug ? styles.desginidmainweb : "displayNone"}`} key={idx}>
                                        {f.categoryProduct && f.categoryProduct.map((productTiles: IProductTiles, idx2: number) =>
                                            <div className={styles.desginwebTiles} key={idx2}>
                                                <Image
                                                    loader={imageLoader}
                                                    src={productTiles.relativePath}
                                                    height={240}
                                                    width={312}
                                                    className="imgFluid"
                                                    alt={productTiles.title}
                                                    title={productTiles.title}
                                                />
                                            </div>
                                        )}
                                        <div className={styles.desIdealastile} id='openExitIntentForm'>
                                            <div className={styles.destileintesbox} ><strong>{f.productCount}+</strong>
                                                <p>{f.categoryName} Designs</p>
                                            </div>
                                            <div className={`btnPrimery ${styles.downLoadbrbox}`}><span>Download Top 50 Designs for Free</span><img className="img-responsive" src="/assets/images/downloadlogo.svg" alt="" /></div>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                        :
                        <>
                            {products && products.map((f, idx) =>
                                <div className={`${active == f.categorySlug ? styles.dedinIdeammobbox : "displayNone"}`} key={idx}>
                                    <div className={styles.desginIdeamaxt}>
                                        {f.categoryProduct && f.categoryProduct.map((productTiles: IProductTiles, idx2: number) =>
                                            <div className={styles.desginterntiles} key={idx2}>
                                                <Image
                                                    loader={imageLoader}
                                                    src={productTiles.relativePath}
                                                    height={240}
                                                    width={312}
                                                    className="imgFluid"
                                                    alt={productTiles.title}
                                                    title={productTiles.title}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.modulerDownnow} id='openExitIntentForm'>
                                        <div className={styles.modulerbtint}>
                                            <span>{f.productCount}+</span>
                                            <p>{f.categoryName} Designs</p>
                                        </div>
                                        <button ><img className="img-responsive" src="/assets/images/downloadlogo.svg" alt="" /></button>
                                    </div>
                                </div>
                            )}
                        </>
                }
            </div>
        </section>
    )
}