import { useState } from 'react';
import styles from './getestimate.module.css'

export function LongBodyGetEstimatedAE() {
    const [readMore, setReadMore] = useState(false);

    return (
        <section className={`${styles.readMore} bgGray`}>
            <div className="container">

                <div
                    className={`${styles.lowerContnt} ${readMore ? styles.fullHeight : null
                        }`}
                >
                    <div>
                        <div>
                            <h2>How to Gauge Home Interior Cost?</h2>
                            <span>Your home is the most sacred place where you let your guard down. Hence, it should be designed in a way that it reflects your personality and lifestyle. The furniture is readily available at reasonable prices in most cases, so choosing the right interior designer is the main task. Every decorator has their own set list of prices charged for specific services.</span>
                            <span>However, other factors must also be considered during the interior design cost estimate. The major factors one should keep in mind to calculate home interior cost are:
                                <ul>
                                    <li>●	 Idea and plan execution, including the design aspects</li>
                                    <li>●	 Modular furnishings vs. custom-made furniture items</li>
                                    <li>●	 Remodelling the structural elements like walls, ceilings, and floors, among other elements</li>
                                    <li>●	 Textures and finishes on floors and walls</li>
                                    <li>●	 Design style and overall fashion aesthetic</li>
                                    <li>●	 The complete size of the project</li>
                                </ul>
                            </span>
                            <span>Choosing the right designer is the ultimate factor in the home interior design estimate. So, let&apos;s understand how interior designer companies work and charge their clients in the UAE.</span>

                            <h3>Turnkey Design Services</h3>
                            <span>These companies provide free design consulting services, usually as bait, if you offer them the contract. In these cases, the contracting interior design estimate charges a high design fee. Hence, it becomes important to segregate execution and design.</span>

                            <h3>Interior Design Firms</h3>
                            <span>The estimation in interior designing of these firms varies depending on the size of the project. The structure and nature of the house also play a crucial role in cost estimation. A sourcing fee is worked upon in most cases.</span>

                            <h3>Furniture Shops</h3>
                            <span>Most furniture shops design your home for free if you purchase all the decor items from them. However, there is a minimum amount that you have to spend on the purchase. The drawback of this type of service is that it restricts the flow of style and scope of innovation since every item is from a different shop.</span>

                            <h3>Online Design Services</h3>
                            <span>The biggest perk of opting for online design services is that they cater to one room at a time. This allows the client to execute their decisions as per the laid budget. It also provides more flexibility in rendering state-of-the-art results for the clients. The cost estimate of interior design is done in a planned way.</span>
                        </div>
                    </div>
                </div>

                <span className={styles.link} onClick={() => setReadMore(!readMore)}>
                    Read {readMore ? "Less" : "More.."}
                </span>
            </div>
        </section>
    );
}

